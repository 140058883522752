<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Footer from "../landing/inc/footer.vue";

export default {
    page: {
        title: "Starter",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Starter",
            items: [{
                text: "Pages",
                href: "/",
            },
            {
                text: "Starter",
                active: true,
            },
            ],
        };
    },
    components: {
        NavMenu,
        Footer
    },
};
</script>

<template>
    <div class="layout-wrapper">

        <nav-menu></nav-menu>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section" id="service">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">대형인쇄소 개발자 출신의</h2>
                            <h1 class="mb-3 fw-semibold lh-base text-success">인쇄분야 웹개발전문 회사</h1>
                            <p class="text-muted">인쇄분야의 오랜 개발경험(10년이상)과 다양한 프로젝트 수행능력으로 귀사에 최적화된 시스템을 맞춤형으로 개발해드립니다.</p>
                            <p class="text-muted fs-13"><span class="text-info">신규개발, 연동개발, 기능확장, 커스트마이징 개발, 유지보수, 필요시 파견개발 등</span></p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="g-4">
                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-body class="py-5 px-4">
                                <i class="ri-shopping-cart-fill fs-48 text-danger"></i>
                                <h5>인쇄쇼핑몰 전문개발</h5>
                                <p class="text-muted fs-13">신규개발, 연동개발, 기능확장, 커스트마이징 개발 등</p>
                                <router-link to="/pages/contactus" class="btn btn-danger">인쇄쇼핑몰 개발안내</router-link>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-body class="py-5 px-4">
                                <i class="ri-building-3-fill fs-48 text-info"></i>
                                <h5>인쇄 MES/POP 맞춤개발</h5>
                                <p class="text-muted fs-13">신규개발, 연동개발, 기능확장, 커스트마이징 개발 등</p>
                                <router-link to="/pages/contactus" class="btn btn-info">MES/POP 개발안내</router-link>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-body class="py-5 px-4">
                                <i class="ri-bar-chart-box-line fs-48 text-primary"></i>
                                <h5 class="pb-2">인쇄 ERP/CRM 맞춤개발</h5>
                                <p class="text-muted fs-13">신규개발, 연동개발, 기능확장, 커스트마이징 개발 등</p>
                                <router-link to="/pages/contactus" class="btn btn-primary">ERP/CRM 개발문의</router-link>
                            </b-card-body>
                        </b-card>
                    </b-col>

                </b-row>
            </b-container>
        </section>

        <section class="section" id="skills">
            <b-container>

                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">보유기술</h2>
                            <p class="text-muted">귀사의 인프라 환경에 맞춰 최적의 개발 환경을 구성합니다.</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="g-4">
                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">프론트엔드</h2><h5 class="fw-semibold">FrontEnd Skil</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/frontend.png" alt="" class="mb-3 pb-2 img-fluid">
                                <b-link href="#!" class="btn btn-soft-info">개발문의</b-link>
                            </b-card-body>
                        </b-card>
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">데이터베이스</h2><h5 class="fw-semibold">Database Skill</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/database.png" alt="" class="mb-3 pb-2 img-fluid">
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">백엔드</h2><h5 class="fw-semibold">BackEnd Skill</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/backend.png" alt="" class="mb-3 pb-2 img-fluid">
                                <b-link href="#!" class="btn btn-soft-info">개발문의</b-link>
                            </b-card-body>
                        </b-card>
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">서버/배포</h2><h5 class="fw-semibold">Deployment Skill</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/deployment.png" alt="" class="mb-3 pb-2 img-fluid">
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="4">
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">모바일/응용</h2><h5 class="fw-semibold">Mobile/App Skill</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/mobile-app.png" alt="" class="mb-3 pb-2 img-fluid">
                                <b-link href="#!" class="btn btn-soft-info">개발문의</b-link>
                            </b-card-body>
                        </b-card>
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">버젼관리</h2><h5 class="fw-semibold">Version Control Skill</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/version-control.png" alt="" class="mb-3 pb-2 img-fluid">
                            </b-card-body>
                        </b-card>
                        <b-card no-body class="text-center border shadow-none">
                            <b-card-header><h2 class="fw-semibold lh-base">커뮤니케이션</h2><h5 class="fw-semibold">Communication Skill</h5></b-card-header>
                            <b-card-body class="py-5 px-5">
                                <img src="@/assets/images/about/communication.png" alt="" class="mb-3 pb-2 img-fluid">
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        
        <section class="section" id="services">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h1 class="mb-3 ff-secondary fw-semibold lh-base">차별화</h1>
                            <p class="text-muted">다온코드만의 차별화된 개발 노하우를 제시합니다.</p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="g-3">
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-pencil-ruler-2-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">다양한 FrontEnd 기술</h5>
                                <p class="text-muted my-3 ff-secondary">전통적인 jQuery 부터 Vue, Angular, React 등의 Major Front Framework 기술을 모두 보유하고 있습니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-palette-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">숙련된 Backend 기술</h5>
                                <p class="text-muted my-3 ff-secondary">주 언어인 PHP ( Codeigniter, Laravel )부터 Python, Node.js, Java Spring boot 등 다양한 개발언어가 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-lightbulb-flash-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">대형인쇄소 개발자 출신</h5>
                                <p class="text-muted my-3 ff-secondary">대형 인쇄소<span class="text-muted">(SW)</span>의 기술연구소에 10년 이상의 근무 경험과 웹사이트 및 ERP, MES, POP 등의 인프라 구축경험을 갖고 있습니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-customer-service-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">다양한 프로젝트 수행능력</h5>
                                <p class="text-muted my-3 ff-secondary">웹에이젼시, 도메인회사, 호스팅회사, 소셜서비스, 온라인마케팅, E-Learing, 쇼핑몰솔류션, 대형인쇄소 등에서의 다양한 개발경험으로 유연한 커뮤니케이션이 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-stack-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">최적화된 ERP Layout 구성</h5>
                                <p class="text-muted my-3 ff-secondary">세련된 디자인과 정리된 Layout를 기반한 UI 구성으로 업무 가시성이 뛰어납니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-settings-2-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">REST API 방식의 개발환경</h5>
                                <p class="text-muted my-3 ff-secondary">REST API, JWT인증 등의 개발표준을 준수한 개발로 유연한 통합 개발환경을 제공합니다.</p>
                            </div>
                        </div>
                    </b-col>

                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-slideshow-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">반응형 시스템구축</h5>
                                <p class="text-muted my-3 ff-secondary">Bootstrap 을 적용한 반응형시스템 구축하여 PC, 모바일, 테블릿 다양한 디바이스에서도 최적화된 화면 구성을 제공합니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-google-fill fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Git 버젼컨트롤관리</h5>
                                <p class="text-muted my-3 ff-secondary">Git, Git Hub 를 통한 형상관리로 버젼관리 및 협업개발 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-briefcase-5-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">Jira 프로젝트 관리</h5>
                                <p class="text-muted my-3 ff-secondary">최신 현업에서 많이 사용하는 Jira를 통한 프로젝트 관리로 일정관리 와 문서화를 진행하여 투명하고 정확한 프로젝트관리가 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="section bg-light" id="portfolio" v-if="false">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">포트폴리오</h2>
                            <p class="text-muted mb-4">다온코드에서 수행한 다양한 프로젝트를 소개합니다.</p>
                            <ul class="nav nav-pills filter-btns justify-content-center" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-medium active" type="button" data-filter="all">전체 프로젝트</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-medium" type="button"
                                        data-filter="artwork">ERP/CRM</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-medium" type="button" data-filter="music">MES/POP</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-medium" type="button" data-filter="games">인쇄쇼핑몰</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-medium" type="button" data-filter="crypto-card">모바일</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link fw-medium" type="button" data-filter="3d-style">기타</button>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="product-item artwork crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/portfolio/crm_iris.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 19.29k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">IRIS</router-link>
                                </h5>
                                <p class="text-muted mb-0">ERP/CRM</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">75.3ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">67.36 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item music crypto-card games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/portfolio/agency_mhtong.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 23.63k </p>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">명함통</router-link>
                                </h5>
                                <p class="text-muted mb-0">기획사 쇼핑몰</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">412.30ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">394.7 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/portfolio/shoppingmall_shopia.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> 더보기</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 15.93k </p>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">쇼피아</router-link>
                                </h5>
                                <p class="text-muted mb-0">판촉 쇼핑몰</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">2.75ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">3.167 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="section bg-light" id="partners" v-if="false">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">협력사</h2>
                            <p class="text-muted">다온코드와 함께하는 협력사를 소개합니다.</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xl="4" md="6">
                        <b-card no-body>
                            <b-card-body>
                                <div class="d-flex">
                                    <div class="flex-shink-0">
                                        <img src="@/assets/images/nft/img-01.jpg" alt=""
                                            class="avatar-sm object-cover rounded" />
                                    </div>
                                    <div class="ms-3 flex-grow-1">
                                        <b-link href="pages-profile">
                                            <h5 class="mb-1">이스트웍스</h5>
                                        </b-link>
                                        <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i>
                                        이러닝컨텐츠 개발</p>
                                    </div>
                                    <div>
                                        <b-dropdown variant="ghost-primary" right toggle-class="arrow-none btn-icon"
                                            class="float-end" no-caret>
                                            <template #button-content><i
                                                    class="ri-more-fill align-middle fs-16"></i></template>
                                            <b-dropdown-item href="#">Share</b-dropdown-item>
                                            <b-dropdown-item href="#">Report</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col xl="4" md="6">
                        <b-card no-body>
                            <b-card-body>
                                <div class="d-flex">
                                    <div class="flex-shink-0">
                                        <img src="@/assets/images/users/avatar-5.jpg" alt=""
                                            class="avatar-sm object-cover rounded">
                                    </div>
                                    <div class="ms-3 flex-grow-1">
                                        <b-link href="pages-profile">
                                            <h5 class="mb-1">회사1</h5>
                                        </b-link>
                                        <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i>
                                            디자인</p>
                                    </div>
                                    <div>
                                        <b-dropdown variant="ghost-primary" right toggle-class="arrow-none btn-icon"
                                            class="float-end" no-caret>
                                            <template #button-content><i
                                                    class="ri-more-fill align-middle fs-16"></i></template>
                                            <b-dropdown-item href="#">Share</b-dropdown-item>
                                            <b-dropdown-item href="#">Report</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col xl="4" md="6">
                        <b-card no-body>
                            <b-card-body>
                                <div class="d-flex">
                                    <div class="flex-shink-0">
                                        <img src="@/assets/images/nft/img-06.jpg" alt=""
                                            class="avatar-sm object-cover rounded">
                                    </div>
                                    <div class="ms-3 flex-grow-1">
                                        <b-link href="pages-profile">
                                            <h5 class="mb-1">회사2</h5>
                                        </b-link>
                                        <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i>
                                            개발</p>
                                    </div>
                                    <div>
                                        <b-dropdown variant="ghost-primary" right toggle-class="arrow-none btn-icon"
                                            class="float-end" no-caret>
                                            <template #button-content><i
                                                    class="ri-more-fill align-middle fs-16"></i></template>
                                            <b-dropdown-item href="#">Share</b-dropdown-item>
                                            <b-dropdown-item href="#">Report</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>

                </b-row>
            </b-container>
        </section>

        <section class="py-5 bg-primary position-relative">
            <div class="bg-overlay bg-overlay-pattern opacity-50"></div>
            <b-container>
                <b-row class="align-items-center gy-4">
                    <b-col sm>
                        <div>
                            <h4 class="text-white mb-0 fw-semibold">인쇄분야 웹개발은 다온코드에 맡겨보세요.</h4>
                        </div>
                    </b-col>

                    <b-col sm="auto">
                        <div>
                            <router-link to="/pages/contactus" class="btn bg-gradient btn-danger">개발문의</router-link>
                        </div>
                    </b-col>

                </b-row>

            </b-container>

        </section>
        <Footer></Footer>
    </div>
</template>